import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { provideRouter, withComponentInputBinding, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalService,
} from '@azure/msal-angular';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';

import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './configs/msal.config';
import { ROOT_INTERCEPTORS } from './app.interceptors';
import { ROUTES } from './app.routes';

import * as store from './store';
import * as translate from './configs/translate.config';

registerLocaleData(localeIt);

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(ROUTES, withComponentInputBinding(), withViewTransitions()),
    provideStore(store.reducers, { metaReducers: store.metaReducers }),
    provideStoreDevtools(store.devtoolsConfig),
    provideEffects(store.effects),
    provideAnimations(),
    importProvidersFrom(TranslateModule.forRoot(translate.config)),
    { provide: LOCALE_ID, useValue: 'it' },
    ...ROOT_INTERCEPTORS,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
};
