import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'dias', pathMatch: 'full' },
  {
    path: 'dias',
    loadChildren: () => import('./features/main/main.routes'),
    canActivate: [MsalGuard],
  },
  { path: '**', redirectTo: 'dias/homepage' },
];
