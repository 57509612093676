/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs';

import { TokenDetails } from '../models/auth/token-details.type';
import { AuthActions } from '../store/actions/auth.actions';
import { selectUserTenants } from '../store/selectors/auth.selectors';
import { AppState } from '../store/states/app.state';
import { AuthenticationService } from '../providers/services/authentication.service';
import { Tenant } from '../models/auth/admin-tool.type';

@Directive({ selector: '[cubeMsalFlow]', standalone: true })
export class CubeMSALFlowDirective {
  private readonly msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);
  private readonly store: Store<AppState> = inject(Store);
  private readonly authService: AuthenticationService = inject(AuthenticationService);

  constructor() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed()
      )
      .subscribe({
        next: () => {
          this.store.dispatch(AuthActions.setActiveAccount());
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) =>
          ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS] as Array<EventType>).includes(message.eventType)
        ),
        map((message: EventMessage) => {
          const tokenDetails = message.payload as unknown as TokenDetails;
          return tokenDetails;
        }),
        switchMap((tokenDetails: TokenDetails) =>
          this.store.select(selectUserTenants).pipe(
            filter((tenants: Array<Tenant>) => !tenants.length),
            map(() => tokenDetails),
            take(1)
          )
        ),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (tokenDetails: TokenDetails) => {
          this.store.dispatch(AuthActions.setTokenDetails({ tokenDetails }));
          this.authService.token.next(tokenDetails);
          this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
        },
      });
  }
}
