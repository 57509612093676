import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

import { AppState } from './store/states/app.state';
import { Theme } from './models/core/theme.type';
import { selectTheme } from './store/selectors/system.selectors';
import { CubeLayoutModeHandlerDirective } from './directives/layout-mode-handler.directive';
import { CubeMSALFlowDirective } from './directives/msal-flow.directive';
import { NotificationHandlerDirective } from './directives/notification-handler.directive';
import { CubeTranslateDirective } from './directives/translate.directive';

@Component({
  selector: 'cube-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    CubeMSALFlowDirective,
    NotificationHandlerDirective,
    CubeTranslateDirective,
    CubeLayoutModeHandlerDirective,
  ],
})
export class AppComponent {
  private readonly store: Store<AppState> = inject(Store);

  isDarkTheme: boolean = false;
  themeClass: string = 'light-theme';

  constructor() {
    this.store
      .select<Theme>(selectTheme)
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((theme: Theme): boolean => theme === 'dark'),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
          const newThemeClass: string = isDark ? 'dark-theme' : 'light-theme';

          const bodyElement = document.body;
          if (bodyElement) {
            bodyElement.classList.remove(this.themeClass);
            bodyElement.classList.add(newThemeClass);
            this.themeClass = newThemeClass;
          }
        },
      });
  }
}
