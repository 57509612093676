import { createSelector } from '@ngrx/store';
import { LayoutMode } from 'src/app/models/core/layout-mode.type';
import { SystemNotification } from 'src/app/models/core/system-notification.type';
import { Theme } from 'src/app/models/core/theme.type';
import { AppState } from '../states/app.state';
import { SystemState } from '../states/system.state';
import { LanguageOption } from 'src/app/models/core/language-option.type';

const systemState = (state: AppState): SystemState => state.system;
export const selectCurrentLang = createSelector(systemState, (state: SystemState): LanguageOption => state.currentLang);
export const selectLayoutMode = createSelector(systemState, (state: SystemState): LayoutMode => state.layoutMode);
export const selectLoading = createSelector(systemState, (state: SystemState): boolean => state.loading);
export const selectNotification = createSelector(systemState, (state: SystemState): SystemNotification | undefined => state.notification);
export const selectTheme = createSelector(systemState, (state: SystemState): Theme => state.theme);
