import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize, Observable } from 'rxjs';
import { SystemActions } from 'src/app/store/actions/system.actions';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  counter = 0;

  constructor(private readonly store: Store) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.counter) {
      this.store.dispatch(SystemActions.startLoading());
    }

    this.counter++;

    return next.handle(req).pipe(
      finalize(() => {
        this.counter--;

        if (!this.counter) {
          this.store.dispatch(SystemActions.stopLoading());
        }
      })
    );
  }
}
