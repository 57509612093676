import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../models/response-model.type';

interface IDiasBaseEffects {
  handleError(error: HttpErrorResponse): Error;
}

@Injectable()
export class DiasBaseEffects implements IDiasBaseEffects {
  handleError<T>(error: HttpErrorResponse): Error {
    return new Error((error.error as ResponseModel<T>).error);
  }
}
