import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { MessageType } from 'src/app/models/core/system-notification.type';
import { ResponseModel } from 'src/app/models/response-model.type';
import { SystemActions } from 'src/app/store/actions/system.actions';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((response: HttpEvent<unknown>) => {
        const res: HttpResponse<ResponseModel<unknown>> = response as HttpResponse<ResponseModel<unknown>>;
        if (res.body?.message) {
          this.store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Info, message: res.body.message } }));
        }
      }),
      catchError((response: HttpErrorResponse) => {
        if (!req.url.includes('assets/i18n')) {
          let message = '';

          if (req.url.includes('blob.core.windows.net') && response.status === HttpStatusCode.NotFound) {
            message = response.statusText;
          }

          if (response.error?.error) {
            if (typeof response.error?.error === 'string') {
              message = response.error.error;
            } else {
              // temporary fix
              message = response.error.error.error_message ?? '';
            }
          }

          this.store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Error, message } }));
        }

        return throwError(() => response);
      })
    );
  }
}
