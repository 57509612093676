import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AzureTokenInterceptor } from './providers/interceptors/azure-token.interceptor';
import { LoadingInterceptor } from './providers/interceptors/loading.interceptor';
import { MetadataInterceptor } from './providers/interceptors/metadata.interceptor';
import { NotificationInterceptor } from './providers/interceptors/notification.interceptor';

export const ROOT_INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: AzureTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MetadataInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
];
