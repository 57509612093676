import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MetadataInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const active_tenant = localStorage.getItem(`${environment.localStorageKeyPrefix}_tenant`);
    const excludedResources: Array<string> = ['https://diassteeldecarbonstorage.blob.core.windows.net', 'https://api.powerbi.com'];
    let headers: HttpHeaders = req.headers;

    if (!excludedResources.some((res) => req.url.includes(res))) {
      headers = req.headers.append('x-solution', environment.solution);

      if (active_tenant) {
        headers = headers.append('x-company', active_tenant);
      }
    }

    const clonedReq = req.clone({ headers });

    return next.handle(clonedReq);
  }
}
