/* eslint-disable @ngrx/no-typed-global-store */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { TokenDetails, TokenDetailsAccountClaims } from 'src/app/models/auth/token-details.type';
import { AppState } from 'src/app/store/states/app.state';
import { Store } from '@ngrx/store';
import { selectTokenDetails } from 'src/app/store/selectors/auth.selectors';
import { environment } from 'src/environments/environment';
import { AuthState } from 'src/app/store/states/auth.state';

@Injectable()
export class AzureTokenInterceptor implements HttpInterceptor {
  readonly authService: AuthenticationService = inject(AuthenticationService);
  readonly #store: Store<AppState> = inject(Store<AppState>);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const request = this.authService.tokenRequest();
    //const tokenDetails: TokenDetails | undefined = this.authService.token.getValue();

    return this.#store.select(selectTokenDetails).pipe(
      take(1),
      map((accountDetails: TokenDetails | undefined): TokenDetails => {
        if (!accountDetails) {
          const localValue = localStorage.getItem(`${environment.localStorageKeyPrefix}_auth`);

          if (localValue) {
            const authState = JSON.parse(localValue) as AuthState | undefined;
            if (authState) {
              accountDetails = authState.tokenDetails as TokenDetails;
              this.authService.token.next(accountDetails);
            }
          }
        }

        return accountDetails as TokenDetails;
      }),
      /*     filter((accountDetails: TokenDetails | undefined): accountDetails is TokenDetails => {
        if (!accountDetails) {
          throw new AuthError('invalid account');
        }
 
        return !!accountDetails;
      }), */
      map((accountDetails: TokenDetails | undefined) => ({
        isValidToken: this.authService.isValidToken((accountDetails?.idTokenClaims as TokenDetailsAccountClaims)?.exp),
        idToken: accountDetails?.idToken ?? '',
        appendToken: this.authService.resourceNeedsToken(req.url),
      })),
      /*       filter(({ isValidToken, idToken, appendToken }) => appendToken && (!isValidToken || !idToken)),
       */ switchMap(({ isValidToken, idToken, appendToken }) => {
        if (!isValidToken && appendToken) {
          return this.authService.acquireTokenSilent(request).pipe(
            /*  tap((response) => this.authService.token.next(response as unknown as TokenDetails)), */
            map(({ idToken }) => ({ idToken, appendToken }))
          );
        } else {
          return of({ idToken, appendToken });
        }
      }),
      switchMap(({ idToken, appendToken }) => {
        const clonedReq = this.authService.cloneRequest(req, idToken, appendToken);
        return next.handle(clonedReq);
      }),
      catchError((err: HttpErrorResponse) => {
        /*       if (err.status === HttpStatusCode.Unauthorized || err instanceof AuthError) {
          if (this.authService.getActiveAccount()) {
            this.authService.logout();
          }
        } */
        if (err.status === HttpStatusCode.Unauthorized || err instanceof InteractionRequiredAuthError) {
          this.authService.acquireTokenRedirect(request);
        }
        return throwError(() => err);
      })
    );
  }
}
