import { HttpResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';

export const FileActions = createActionGroup({
  source: 'File',
  events: {
    RetrieveSASTokenUrl: props<{ url: string }>(),
    RetrieveSASTokenUrlSuccess: props<{ url: string }>(),
    RetrieveSASTokenUrlFailure: props<{ error: Error }>(),
    RetrieveZIPUrl: props<{ resource: string; id: string }>(),
    RetrieveZIPUrlSuccess: props<{ url: string }>(),
    RetrieveZIPUrlFailure: props<{ error: Error }>(),
    DownloadFromUrl: props<{ url: string }>(),
    DownloadFromUrlSuccess: props<{ response: HttpResponse<Blob> }>(),
    DownloadFromUrlFailure: props<{ error: Error }>(),
  },
});
